const tableData = [
  {
    reference: 'SESSION 1 MARCH 2022',
    session: 'SESSION 1',
    month: 'March',
    year: 2018,
    state: 'PENDING',
  }, {
    reference: 'SESSION 1 MARCH 2022',
    session: 'SESSION 1',
  month: 'August',
    year: 2018,
    state: 'PENDING',
  }, {
    reference: 'SESSION 1 MARCH 2022',
    session: 'SESSION 1',
    month: 'March',
    year: 2018,
    state: 'ON GOING',
  }, {
    reference: 'SESSION 1 MARCH 2022',
    session: 'PTRound1',
    month: 'August',
    year: 2019,
    state: 'PENDING',
  }, {
    reference: 'SESSION 5 MARCH 2022',
    session: 'SESSION 5',
    month: 'March',
    year: 2019,
    state: 'PENDING',
  }, {
    reference: 'SESSION 1 MARCH 2022',
    session: 'SESSION 1',
    month: 'March',
    year: 2018,
    state: 'ON GOING',
  }, {
    reference: 'SESSION 2 MARCH 2022',
    session: 'SESSION 2',
    month: 'March',
    year: 2018,
    state: 'COMPLETED',
  },

];

export { tableData };
